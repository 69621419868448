<template>
  <v-container
    fluid
    tag="section"
  >

<v-dialog  v-model="alert_msg"  width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Mensagem
        </v-card-title>

        <v-card-text>
            {{alert_msg_descricao}}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="alert_msg = false"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
</v-dialog>



  <v-dialog  v-model="dlg_novo"  max-width="600px">
     <v-card>

    <v-toolbar
                                      text
                                        color="primary"
                                        dark
                                      > <h3> Novo Usuário</h3>
                          </v-toolbar>
     


        <v-card-text>
          <v-form ref="form" style="margin-top:20px"     lazy-validation> 

          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field v-model="signupRequest.username" style="font-size:20px; font-color:red;" :counter="30" 
                  outlined :rules="nameRules"
                  clearable 
                  label="Usuário"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-text-field v-model="signupRequest.email" style="font-size:20px; font-color:red;" :counter="50" 
                    outlined :rules="emailRules"
                  clearable 
                  label="E-mail" required
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-text-field v-model="signupRequest.password" style="font-size:20px; font-color:red;" :counter="8" 
                     outlined :rules="passRules"
                  clearable 
                  type="password"
                  label="Senha"
                  required
                ></v-text-field>
              </v-col>

              </v-row>
          </v-container>
          <small>*campos requeridos</small>
           </v-form>

        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            text 
            @click="dlg_novo = false"
          >
            Sair
          </v-btn>
          <v-btn 
            color="success"
            @click="cadastrar"
          >
            OK
            <v-icon
        right
        dark
      >
        mdi-check
      </v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>

    <v-dialog  v-model="dlg_permissao"  width="600">
     <v-card>

    <v-toolbar
                                      text
                                        color="primary"
                                        dark
                                      > <h3> Lista Permissões</h3>
                          </v-toolbar>
     


        <v-card-text>
          <v-container>
            <v-row>
             
          <v-data-table :single-select="false"  :loading="loading_roles" :items-per-page="100" hide-default-footer :headers="headers_roles" v-model="user.role"  :items="roles"  show-select item-key="name">
          </v-data-table>   
          </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            text 
            @click="dlg_permissao = false"
          >
            Sair
          </v-btn>
          <v-btn 
            color="success"
            @click="cadastrar_permissao()"
          >
            OK
            <v-icon
        right
        dark
      >
        mdi-check
      </v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>


<base-material-card
      icon="mdi-account"
      title="Usuários Cadastrados"
      class="px-5 py-3"
    >
      <v-simple-table :loading="loading">
            <thead>
              <tr>
                <th style="width: 35%;font-size:14px;font-weight: bold;">
                  Nome
                </th>
                <th  style="width: 20%;;font-size:14px;font-weight: bold;">
                  Email
                </th>
    
                <th  style="width: 15%;;font-size:14px;font-weight: bold;">
                  Situação
                </th>

                <th style="width: 30%;;font-size:14px;font-weight: bold;">
                  Ações
                </th>
              </tr>
            </thead>

            <tbody>
              <tr
                v-for="(item, index) in usuarios"
                :key="item.id"
              >
                <td>{{ item.username }}</td>
                <td>{{ item.email }}</td>
                <td>

                    <v-chip
                          class="ma-2"
                          :color="getBadge(item.ativo)"
                          text-color="white"
                        >
                              {{getStatus(item.ativo)}}                    
                              
                    </v-chip>

              
              </td>


                <td style="width: 45%;">

                <v-btn style="margin-left:10px"  v-if="item.ativo == false"  :loading="btn_status" color="warning" small @click="mudarStatus(index, item)">Habilitar
                    <v-icon right dark>mdi-check</v-icon>      
                </v-btn>
                <v-btn style="margin-left:10px"  v-if="item.ativo == true"  :loading="btn_status" color="warning" small @click="mudarStatus(index, item)">Desabilitar
                    <v-icon right dark>mdi-close</v-icon>      
                </v-btn>
                <v-btn style="margin-left:10px"  v-if="item.ativo == true"   color="danger" small @click="ajutarPerfil(item)">Perfil
                   <v-icon right dark>mdi-account-box</v-icon>      

                </v-btn>

                </td>
              </tr>
            </tbody>
            <br/>
          </v-simple-table>   
    </base-material-card>

      </br>
      <v-btn color="success"  @click="novo_usuario()" > NOVO USUÁRIO
      
       <v-icon
        right
        dark
      >
        mdi-account-multiple-plus
      </v-icon>

      </v-btn>

  

    
    
  </v-container>
</template>
<script>
  import Usuario from '../../../services/usuario'
  import Roles from '../../../services/roles'

  export default {

     created() {
          if(this.$store.state.auth.status.loggedIn == false){
           this.$router.push('/')
          }
      },

    data: () => ({

    nameRules: [
        v => !!v || 'Campo Obrigatório',
        v => (v && v.length >=4) || 'Usuário deve ter pelo menos 4 caracteres',
        v => (v && v.length <= 30) || 'Usuário deve ter pelo até 30 caracteres',

      ],

       emailRules: [
        v => !!v || 'Campo Obrigatório',
        v => (v && v.length >=5) || 'Email deve ter pelo menos 5 caracteres',
        v => (v && v.length <= 50) || 'Email deve ter pelo até 50 caracteres',

      ],


       passRules: [
        v => !!v || 'Campo Obrigatório',
        v => (v && v.length >=6) || 'Senha deve ter pelo menos 6 caracteres',
        v => (v && v.length <= 8) || 'Senha deve ter pelo até 8 caracteres',

      ],



      

     
      headers_roles: [
          { text: 'Permissão', value: 'name' },
          { text: 'Descricao', value: 'descricao' },
        ],

      usuarios: [],
      dlg_novo: false,
      btn_status: false,
      dlg_permissao: false,
      loading_roles: false,
      alert_msg: false,
      alert_msg_descricao: '',
      user:{
        id:'',
        role:[]
      },
      roles:[],
      loading: false,
      selected: [],
      signupRequest:{
          username: '',
          email: '',
          password: '',
         // role:[],
          empresa: 'Previda',
      },
      dlg_novo: false,
      msg_dialog: '',
      dialog: false,
      checkboxNames: ['01', '02', '03', '04'],
      search: '',
        headers: [
          {
            text: 'Nome',
            filterable: false,
            value: 'name',
          },
          { text: 'Email' },
          { text: 'Situação' },

          { text: 'Ações', value: 'fat' },
        ],



    }),

    mounted () {
      this.listar();
      //this.clear();

    },


    methods: {
      listar () {
       this.loading = true

        Usuario.lista().then(response => {
            this.usuarios = response.data
        }).catch(e => {
            if(e.response.status === 401){
            this.$store.dispatch('auth/logout');
            this.$router.push('/restrito/login');
            }

        }).finally(() => {
            this.loading = false

        })
      },

       listarRoles () {
         this.loading_roles = true;
        Roles.lista().then(response => {
            this.roles = response.data
        }).catch(e => {
            this.loading_roles = false;

        }).finally(() => {
          this.loading_roles = false;
        })
      },

      clear () {       
        this.$refs.form.reset();
      },

      novo_usuario(){
        this.dlg_novo = true;
      },

      cadastrar_permissao(){
           Usuario.updatePermissao(this.user.id, this.user.role).then(response => {
                      this.dlg_permissao = false;
                      this.user = {};

                      }).catch(e => {
                        if(e.response.status === 403){
                            alert("Usuário não tem permissão")
                        }
                        
                      }).finally(() => {

                      })
      },
      cadastrar() {
          if(this.$refs.form.validate()){
              Usuario.cadastro(this.signupRequest).then(response => {
                      this.alert_msg = true
                      if(response.status === 200){
                          this.usuarios.push(response.data)
                          this.alert_msg_descricao = 'Cadastrado com Sucesso'
                      }
                                             
                      this.dlg_novo = false;

                      }).catch(e => {
                          this.dlg_novo = false;
                          this.alert_msg = true
                          this.alert_msg_descricao = 'Usuário ou email já existem'
                         

                        if(e.response.status === 403){
                            alert("Usuário não tem permissão")
                        }

                        
                      }).finally(() => {
                         this.dlg_novo = false;
                         this.signupRequest.username = "",
                         this.signupRequest.email = "",
                         this.signupRequest.password = ""
                      })

          }  
      },
      mudarStatus(pos, item){
            this.btn_status = true;
            Usuario.updateStatusUsuario(item.id).then(response => {
                        this.usuarios.indexOf(item)
          item.ativo = response.data;
          Object.assign(this.usuarios[pos], item)

            //this.usuarios.push(item)


        }).catch(e => {
                        this.btn_status = false;

        }).finally(() => {
                      this.btn_status = false;

        })
      },

       ajutarPerfil(item){
          
          this.user.role = [];
          this.listarRoles();
          //this.btn_carregar_permissao
          this.user = item;
          this.dlg_permissao = true; 
      },

      setDisabled: function(status){
        return status
      },

      setEnable: function(status){
        return !status
      },

      getBadge (status) {
      switch (status) {
         case true: return 'green lighten-3'
        case false: return 'red lighten-2'
        default: 'success'
      }
      },

      getStatus(status) {
      switch (status) {
        case true: return 'Ativo'
        case false: return 'Inativo'
        default: ''
      }
    },

    

    }
  }

</script>
